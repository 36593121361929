import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import bg3 from "../../img/aboutus/aboutusbg3.webp";
import gluten from "../../img/aboutus/gluten1.webp";
import equii from "../../img/aboutus/equii2.webp";
import "./AboutUs3Step.scss";
const AboutUs3Step = () => {
  return (
    <div className="aboutusInnovation">
      <div className="aboutusEquii anchor" id="intraplant">
        <div className="aboutusEquiiHeader">
          <h3>OUR INNOVATION</h3>
          <h2>{"INTRAPLANT TECH\n"}</h2>
          <h4>zero meat, maximum satisfaction</h4>
          <div className="glutenImg">
            <img src={gluten} alt="Gluten" />
          </div>
        </div>
        <div className="aboutusEquiiWrapper">
          <div className="aboutusEquiiContainer">
            <div className="aboutusEquiiImg">
              <img src={equii} alt="3 Step Coated" />
            </div>
            <div className="aboutusEquiiDesc">
              <p>
                {
                  "We call it IntraPlant Tech but you can call it delicious. It’s what makes our plant-based products taste and feel like real meat. Not to worry. You only get the good stuff. Our products are made from plant-based sources like wheat, soy, bamboo and citrus fibers."
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ThreeStepCoatingWrapper anchor" id="threestep">
        <div className="ThreeStepBgWrapper">
          <img src={bg3} alt="background" />
        </div>
        <div className="ThreeStepCoatingContainer">
          <div className="ThreeStepHeader">
            <h2> 3-Step Coating Innovation</h2>
            <p> The Science Behind the Crunch</p>
          </div>
          <div className="ThreeStepContent">
            <div className="ThreeStepImg">
              <StaticImage src="../../img/aboutus/coating.webp" alt="3 Step Coated" />
            </div>
            <div className="ThreeStepDesc" style={{ whiteSpace: "pre-wrap" }}>
              <p>
                {"First we batter, then we pre-dust, then we batter or bread again. This 3-step Coating Innovation is what guarantees natural crispiness and crunch in every bite."}
              </p>
              <p>{"Recipes are also tailored to local taste profiles because we want our products to not just suit but surprise your tastebuds too."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs3Step;
