import React from "react";
import Layout from "../components/Layout";
import AboutUsOurBrand from "../components/AboutUsOurBrand/AboutUsOurBrand";
import AboutUsOurBrandMobile from "../components/AboutUsOurBrand/AboutUsOurBrandMobile";
import "../scss/aboutus.scss";
import AboutUs3Step from "../components/AboutUs3Step/AboutUs3Step";
const About = (props) => {
    return (
        <Layout logo="white" title="About Us" description="Learn about our mission and innovative plant-based products.">
                <>
                    <div className="aboutus">
                        <div className="aboutusBanner">
                            <div className="aboutusBannerContent">
                                <h1>ABOUT US</h1>
                                <h5>OUR BRAND IDEA AND INNOVATION</h5>
                            </div>
                        </div>
                    </div>
                    <div id="idea" className="anchor">
                        <AboutUsOurBrand />
                        <AboutUsOurBrandMobile />
                    </div>
                    <div id="innovation" className="anchor">
                        <AboutUs3Step />
                    </div>
                </>
        </Layout>
    );
};

export default About;
