import React from "react";
import "./AboutUsOurBrand.scss";
import mobileImg1 from "../../img/aboutus/mobile1.webp";
import mobileImg2 from "../../img/aboutus/mobile2.webp";

const AboutUsOurBrandMobile = (props) => {
  return (
    <div className="aboutusOurBrandMobile" style={{ whiteSpace: "pre-wrap" }}>
      <div className="aboutusMobileHeader">
        <h3>Our brand</h3>
        <h2 className="aboutusourBrandsubHeader">SOOO GOOD, NO BAD</h2>
        <h4>THE BEST PLANT-BASED EXPERIENCE</h4>
      </div>
      <div className="aboutusMobileContent">
        <div className="aboutusMobileImg1">
          <img src={mobileImg1} alt="Our Brand1" />
        </div>
        <div className="aboutusMobileDesc">
          <p>
            {"With FIRST PRIDE, you won’t have to choose\nbetween taste and nutrition. Going ".replace(/\n/g, " ")}
            <b>{"plant-based \n".replace(/\n/g, " ")}</b>
            {"doesn’t have to be boring and we’ve made\nsure of that. From crispy to spicy to authentic\nlocal flavors, every FIRST PRIDE product is\nguaranteed to be a tasty snack or meal\nworth looking forward to.".replace(
              /\n/g,
              " "
            )}
          </p>
        </div>
      </div>
      <div className="aboutusMobileOurMission">
        <div className="aboutusMobileOurMissionContent">
          <div className="aboutusOurMissionImg2">
            <img src={mobileImg2} alt="Our Mission 2" />
          </div>
          <div className="aboutusOurMissionContent">
            <h2>OUR MISSION</h2>
            <p>
              {
                "We eat, breathe, and live plant-based. By investing our time and effort into development of alternative protein sources, we’re changing the way people look at plant-based products. We want even the meat-lovers to go, “Hey, I’ll try that!”"
              }
            </p>
            <p>
              {
                "Our focus has always been on taste, nutrition, and the experience. It’s all part of raising expectations of how a quality plant-based diet can be satisfying and healthy at the same time."
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsOurBrandMobile;
