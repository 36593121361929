import React from "react";
import "./AboutUsOurBrand.scss";
import banner1 from "../../img/aboutusOurBrand.webp";
import banner2 from "../../img/aboutus/missionbg3.webp";
import mission from "../../img/aboutus/ourmission.webp";
const AboutUsOurBrand = (props) => {
  return (
    <div className="aboutusourBrand">
      <img src={banner1} alt="Banner 1" className="bannerImg" />
      <div style={{ whiteSpace: "pre-wrap" }} className="aboutusourBrandContent">
        <h3 className="aboutusourBrandbrandHeader">Our brand</h3>
        <h2 className="aboutusourBrandsubHeader">Sooo good, no bad</h2>
        <h3 className="aboutusourBrandsubsubHeader">The best plant-based experience</h3>
        <div className="aboutusourBrandContentWrapper">
          <div className="aboutusourBrandDesc">
            <p>
              {"With FIRST PRIDE, you won’t have to choose\nbetween taste and nutrition. Going "}
              <b>{"plant-based \n"}</b>
              {
                "doesn’t have to be boring and we’ve made\nsure of that. From crispy to spicy to authentic\nlocal flavors, every FIRST PRIDE product is\nguaranteed to be a tasty snack or meal\nworth looking forward to."
              }
            </p>
          </div>
        </div>
      </div>
      <div className="aboutusOurMission">
        <div className="aboutusOurMissionBgWrapper">
          <img src={banner2} className="banner2Img" alt="our mission" />
        </div>
        <div className="aboutusOurMissionImg">
          <img src={mission} alt="our msision" />{" "}
        </div>
        <div className="aboutusOurMissionDesc">
          <h2>OUR MISSION</h2>
          <p>
            {
              "We eat, breathe, and live plant-based. By investing our time and effort into development of alternative protein sources, we’re changing the way people look at plant-based products. We want even the meat-lovers to go, “Hey, I’ll try that!”"
            }
          </p>
          <p>
            {
              "Our focus has always been on taste, nutrition, and the experience. It’s all part of raising expectations of how a quality plant-based diet can be satisfying and healthy at the same time."
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsOurBrand;
